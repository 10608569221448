import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '100px 0',
}))

export const InfoBlock = styled.div(({theme}) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.primary.green}`,

  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
  },
}))

export const TitleBlock = styled.div(({theme}) => ({
  padding: '15px 57px',
  maxWidth: 370,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.primary.green,

  [theme.breakpoints.down('tabletL')]: {
    padding: '15px',
    maxWidth: '100%',
  },
}))

export const TextBlock = styled.div(({theme}) => ({
  padding: '50px 90px 39px 80px',

  [theme.breakpoints.down('tabletL')]: {
    padding: '15px',
  },
}))
