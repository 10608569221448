import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Wrapper, Inner} from './Repayment.styles'
import ContentContainer from '../ContentContainer'
import Text from '../Text'

const Repayment = ({children, ...rest}) => {
  const {t} = useI18next()
  return (
    <Wrapper {...rest} id="repayment">
      <ContentContainer>
        <Inner>
          <Text content={t('sections.repayment.title')} size="titleXxs" mb={50} uppercase />
          <table>
            <tbody>
              <tr>
                <th>&nbsp;</th>
                <th>{t('sections.repayment.table.fall1')}</th>
                <th>{t('sections.repayment.table.fall2')}</th>
                <th>{t('sections.repayment.table.fall3')}</th>
                <th>{t('sections.repayment.table.fall4')}</th>
              </tr>
              <tr>
                <td>{t('sections.repayment.table.title1')}</td>
                <td>€ 1.000</td>
                <td>€ 5.000</td>
                <td>€ 50.000</td>
                <td>€ 200.000</td>
              </tr>
              <tr>
                <td>{t('sections.repayment.table.title2')}</td>
                <td>€ 12.000</td>
                <td>€ 60.000</td>
                <td>€ 600.000</td>
                <td>€ 1.200.000</td>
              </tr>
              <tr>
                <td>{t('sections.repayment.table.title3')}</td>
                <td>€ 48.000</td>
                <td>€ 240.000</td>
                <td>€ 2.400.000</td>
                <td>€ 9.600.000</td>
              </tr>
              <tr>
                <td className="green">{t('sections.repayment.table.title4')}</td>
                <td className="green">€ 1.800 - € 2.800</td>
                <td className="green">€ 9.100 – € 14.400</td>
                <td className="green">€ 91.000 – € 144.000</td>
                <td className="green">€ 364.800 – € 576.000</td>
              </tr>
            </tbody>
          </table>
        </Inner>
      </ContentContainer>
    </Wrapper>
  )
}

Repayment.propTypes = {}
Repayment.defaultProps = {}

export default Repayment
