import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  paddingBottom: 100,
}))

export const Inner = styled.div(({theme}) => ({
  padding: '32px 54px 42px 54px',
  border: `1px solid ${theme.palette.primary.green}`,

  [theme.breakpoints.down('tabletL')]: {
    padding: '15px',
  },

  [theme.breakpoints.down('mobileS')]: {
    margin: '0 -15px',
  },

  '& table': {
    width: '100%',
    fontFamily: theme.fontFamilies.lato,

    '& th': {
      paddingBottom: 28,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: theme.fontSizes.lg,
      fontWeight: 700,
      textAlign: 'left',

      [theme.breakpoints.down('tabletL')]: {
        fontSize: theme.fontSizes.def,
      },

      [theme.breakpoints.down('tabletS')]: {
        fontSize: theme.fontSizes.xs,
      },

      [theme.breakpoints.down('mobileL')]: {
        fontSize: theme.fontSizes.xxs,
      },
    },

    '& td': {
      paddingBottom: 21,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: theme.fontSizes.md,
      textAlign: 'left',

      [theme.breakpoints.down('tabletL')]: {
        fontSize: theme.fontSizes.def,
      },

      [theme.breakpoints.down('tabletS')]: {
        fontSize: theme.fontSizes.xs,
      },

      [theme.breakpoints.down('mobileL')]: {
        fontSize: theme.fontSizes.xxs,
      },
    },

    '& td.green': {
      paddingTop: 31,
      fontWeight: 700,
      color: theme.palette.primary.green,
    },

    '& td:first-of-type': {
      fontSize: theme.fontSizes.lg,
      fontWeight: 700,

      [theme.breakpoints.down('tabletL')]: {
        fontSize: theme.fontSizes.def,
      },

      [theme.breakpoints.down('tabletS')]: {
        fontSize: theme.fontSizes.xs,
      },

      [theme.breakpoints.down('mobileL')]: {
        fontSize: theme.fontSizes.xxs,
      },
    },
  },
}))
