import React from 'react'
import SEO from 'components/SEO'
import {useI18next} from 'gatsby-plugin-react-i18next'
import Legal from '../sections/Legal'
import Hero from '../sections/Hero'
import Benefits from '../sections/Benefits'
import FurtherInfo from '../sections/FurtherInfo'
import Performance from '../components/Performance'
import AboutUs from '../sections/AboutUs'
import Repayment from '../components/Repayment'
import ShowMob from '../components/ShowMob'
import FormAssessmentMob from '../components/FormAssessmentMob'
import {FormAssessmentMobWrapper} from '../components/FormAssessmentMob/FormAssessmentMob.styles'

export default function MainPage({data, location}) {
  const {t} = useI18next()
  return (
    <>
      <SEO title={t('pages.main.seoTitle')} description={t('pages.main.seoDescription')} />
      <Hero />
      <Benefits />
      <Legal />
      <FurtherInfo />
      <Performance />
      <Repayment />
      <AboutUs />
      <ShowMob>
        <FormAssessmentMobWrapper>
          <FormAssessmentMob boxShadow />
        </FormAssessmentMobWrapper>
      </ShowMob>
    </>
  )
}
