import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {InfoBlock, TextBlock, TitleBlock, Wrapper} from './FurtherInfo.styles'
import ContentContainer from '../../components/ContentContainer'
import Text from '../../components/Text'

const FurtherInfo = ({children, ...rest}) => {
  const {t} = useI18next()
  return (
    <Wrapper {...rest} id="furtherInfo">
      <ContentContainer>
        <InfoBlock>
          <TitleBlock>
            <Text
              content={t('sections.furtherInfo.title')}
              size="titleXs"
              mb={0}
              uppercase
              maxWidth={256}
              textAlign="center"
              color="common.white"
            />
          </TitleBlock>
          <TextBlock>
            <Text
              content={t('sections.furtherInfo.text1')}
              size="sm"
              mb={15}
              maxWidth={627}
              fontLato
            />
            <Text
              content={t('sections.furtherInfo.text2')}
              size="sm"
              mb={0}
              maxWidth={627}
              fontLato
            />
          </TextBlock>
        </InfoBlock>
      </ContentContainer>
    </Wrapper>
  )
}

FurtherInfo.propTypes = {}
FurtherInfo.defaultProps = {}

export default FurtherInfo
