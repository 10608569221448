import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {
  Wrapper,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  ItemInner,
  ItemOuter,
  ItemsInner,
  ItemsOuter,
  AbsLine1,
  AbsLine2,
  InnerWrapper,
} from './Legal.styles'
import ContentContainer from '../../components/ContentContainer'
import Text from '../../components/Text'

const Legal = ({children, ...rest}) => {
  const {t} = useI18next()
  return (
    <Wrapper {...rest} id="benefits">
      <ContentContainer>
        <InnerWrapper>
          <Text
            component="h2"
            content={t('sections.legal.title')}
            size="titleSm"
            mb={30}
            uppercase
          />
          <Text content={t('sections.legal.subtitle')} size="md" mb={50} maxWidth={660} />
        </InnerWrapper>
      </ContentContainer>
      <ItemsOuter>
        <AbsLine1 />
        <AbsLine2 />
        <ContentContainer>
          <InnerWrapper>
            <ItemsInner>
              <ItemOuter>
                <ItemInner>
                  <Icon1 />
                  <Text content={t('sections.legal.itemText1')} size="md" mb={0} />
                </ItemInner>
              </ItemOuter>
              <ItemOuter>
                <ItemInner>
                  <Icon2 />
                  <Text content={t('sections.legal.itemText2')} size="md" mb={0} />
                </ItemInner>
              </ItemOuter>
              <ItemOuter>
                <ItemInner>
                  <Icon3 />
                  <Text content={t('sections.legal.itemText3')} size="md" mb={0} />
                </ItemInner>
              </ItemOuter>
              <ItemOuter>
                <ItemInner>
                  <Icon4 />
                  <Text content={t('sections.legal.itemText4')} size="md" mb={0} />
                </ItemInner>
              </ItemOuter>
            </ItemsInner>
          </InnerWrapper>
        </ContentContainer>
      </ItemsOuter>
    </Wrapper>
  )
}

Legal.propTypes = {}
Legal.defaultProps = {}

export default Legal
