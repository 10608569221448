import styled from 'styled-components'

export const Wrapper = styled.div(({theme, negativeMargin}) => ({
  display: 'none',
  overflow: 'hidden',

  [theme.breakpoints.down('laptopS')]: {
    display: 'block',
  },

  [theme.breakpoints.down('mobileL')]: {
    margin: negativeMargin ? '0 -15px' : 0,
    display: 'block',
  },
}))
