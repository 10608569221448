import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Wrapper, Title, Subtitle, ContentWrapper, BtnWrapper} from './Hero.styles'
import {FormButton} from '../../components/FormAssessmentMob/FormAssessmentMob.styles'
import ShowMob from '../../components/ShowMob'

const Hero = ({children, ...rest}) => {
  const {t} = useI18next()

  return (
    <Wrapper {...rest}>
      <ContentWrapper>
        <Title component="h1" content={t('pages.main.hero.title')} />
        <Subtitle content={t('pages.main.hero.subtitle')} />
        <ShowMob>
          <BtnWrapper>
            <FormButton w100 variant="green" uppercase to="#contactMob">
              {t('forms.assessment.btnSubmit')}
              <span>{t('forms.assessment.btnSubmitCaption')}</span>
            </FormButton>
          </BtnWrapper>
        </ShowMob>
      </ContentWrapper>
    </Wrapper>
  )
}

Hero.propTypes = {}
Hero.defaultProps = {}

export default Hero
