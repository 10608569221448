import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {
  Icon1,
  ItemInner,
  ItemOuter,
  ItemsInner,
  ItemsOuter,
  Icon3,
  Icon2,
  WrapperAll,
  WrapperTitle,
  AbsLine1,
  AbsLine2,
} from './Performance.styles'
import Text from '../Text'
import ContentContainer from '../ContentContainer'

const Performance = ({children, ...rest}) => {
  const {t} = useI18next()
  return (
    <WrapperAll id="performance">
      <WrapperTitle {...rest}>
        <ContentContainer>
          <Text
            component="h2"
            content={t('sections.performance.title')}
            size="titleSm"
            mb={30}
            uppercase
            textAlign="center"
            centered
          />
          <Text
            content={t('sections.performance.subtitle')}
            size="md"
            mb={50}
            textAlign="center"
            maxWidth={680}
            centered
          />
        </ContentContainer>
      </WrapperTitle>
      <ItemsOuter>
        <AbsLine1 />
        <AbsLine2 />
        <ContentContainer>
          <ItemsInner>
            <ItemOuter>
              <ItemInner>
                <Icon1 />
                <Text content={t('sections.performance.itemTitle1')} size="titleXxs" uppercase />
                <Text content={t('sections.performance.itemText1')} />
              </ItemInner>
            </ItemOuter>
            <ItemOuter>
              <ItemInner>
                <Icon2 />
                <Text content={t('sections.performance.itemTitle2')} size="titleXxs" uppercase />
                <Text content={t('sections.performance.itemText2')} />
              </ItemInner>
            </ItemOuter>
            <ItemOuter>
              <ItemInner>
                <Icon3 />
                <Text content={t('sections.performance.itemTitle3')} size="titleXxs" uppercase />
                <Text content={t('sections.performance.itemText3')} />
              </ItemInner>
            </ItemOuter>
          </ItemsInner>
        </ContentContainer>
      </ItemsOuter>
    </WrapperAll>
  )
}

Performance.propTypes = {}
Performance.defaultProps = {}

export default Performance
