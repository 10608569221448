import styled from 'styled-components'
import Text from '../../components/Text'

export const Wrapper = styled.div(({theme}) => ({
  maxWidth: 676,
  width: '100%',
  padding: '90px 0 117px',
}))

export const BlockWrapper = styled.div(({theme}) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const Block = styled.div(({theme}) => ({
  maxWidth: 270,
  width: '100%',
  marginTop: 60,
  display: 'flex',
  justifyContent: 'space-between',
}))

export const BlockTextWrapper = styled.div(({theme}) => ({
  maxWidth: 200,
  width: '100%',
}))

export const BlockImage = styled.img(({theme}) => ({
  maxWidth: 60,
  width: '100%',
  marginRight: 10,
}))

export const Title = styled(Text)(({theme}) => ({
  fontSize: 32,
  fontWeight: 700,
  marginBottom: 0,
  textTransform: 'uppercase',
  [theme.breakpoints.down('tabletS')]: {
    textAlign: 'center',
  },
}))

export const BlockTitle = styled(Text)(({theme}) => ({
  fontSize: theme.fontSizes.md,
  fontWeight: 700,
  marginBottom: 10,
  textTransform: 'uppercase',
}))

export const BlockText = styled(Text)(({theme}) => ({}))
