import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {
  Wrapper,
  Title,
  BlockWrapper,
  Block,
  BlockImage,
  BlockTextWrapper,
  BlockTitle,
  BlockText,
} from './Benefits.styles'
import ContentContainer from '../../components/ContentContainer'
import img1 from '../../images/index/benefits/icon_euro.svg'
import img2 from '../../images/index/benefits/icon_justice.svg'
import img3 from '../../images/index/benefits/icon_timer.svg'
import img4 from '../../images/index/benefits/icon_clock.svg'

const Benefits = ({children, ...rest}) => {
  const {t} = useI18next()

  return (
    <ContentContainer>
      <Wrapper {...rest}>
        <Title component="h2" content={t('pages.main.benefits.title')} />
        <BlockWrapper>
          <Block>
            <BlockImage src={img1} />
            <BlockTextWrapper>
              <BlockTitle content={t('pages.main.benefits.item1Title')} />
              <BlockText content={t('pages.main.benefits.item1Text')} />
            </BlockTextWrapper>
          </Block>
          <Block>
            <BlockImage src={img2} />
            <BlockTextWrapper>
              <BlockTitle content={t('pages.main.benefits.item2Title')} />
              <BlockText content={t('pages.main.benefits.item2Text')} />
            </BlockTextWrapper>
          </Block>
          <Block>
            <BlockImage src={img3} />
            <BlockTextWrapper>
              <BlockTitle content={t('pages.main.benefits.item3Title')} />
              <BlockText content={t('pages.main.benefits.item3Text')} />
            </BlockTextWrapper>
          </Block>
          <Block>
            <BlockImage src={img4} />
            <BlockTextWrapper>
              <BlockTitle content={t('pages.main.benefits.item4Title')} />
              <BlockText content={t('pages.main.benefits.item4Text')} />
            </BlockTextWrapper>
          </Block>
        </BlockWrapper>
      </Wrapper>
    </ContentContainer>
  )
}

Benefits.propTypes = {}
Benefits.defaultProps = {}

export default Benefits
