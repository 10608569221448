import styled from 'styled-components'
import Text from '../../components/Text'
import bg from '../../images/index/hero/bg.jpg'
import ContentContainer from '../../components/ContentContainer'

export const Wrapper = styled.div(({theme}) => ({
  paddingTop: 100,
  paddingBottom: 73,
  background: `url(${bg})`,
  backgroundSize: 'cover',

  [theme.breakpoints.down('laptopS')]: {
    paddingBottom: 0,
  },
}))

export const ContentWrapper = styled(ContentContainer)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))
export const Title = styled(Text)(({theme}) => ({
  maxWidth: 700,
  color: '#FFFFFF',
  fontSize: 64,
  fontWeight: 700,
  marginBottom: 20,
  lineHeight: 1,
  [theme.breakpoints.down('tablet')]: {
    fontSize: 50,
  },
  [theme.breakpoints.down('mobileL')]: {
    fontSize: 44,
  },
}))
export const Subtitle = styled(Text)(({theme}) => ({
  color: '#FFFFFF',
  maxWidth: 390,
  width: '100%',
  fontSize: theme.fontSizes.title,

  [theme.breakpoints.down('laptopS')]: {
    marginBottom: 50,
  },

  [theme.breakpoints.down('tablet')]: {
    fontSize: 24,
  },
  [theme.breakpoints.down('mobileL')]: {
    fontSize: 20,
  },
}))

export const BtnWrapper = styled.div(({theme}) => ({
  margin: '0 auto',
  maxWidth: 390,
  width: '100%',
}))
