import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Wrapper, WorldIcon} from './CommonContactsText.styles'
import Text from '../Text'

const CommonContactsText = ({children, ...rest}) => {
  const {t} = useI18next()
  return (
    <Wrapper {...rest}>
      <Text size="md" color="primary.green" mb={20} mt={15} fontWeight={700}>
        <WorldIcon />
        {t('sections.aboutUs.contactPerson.worldwideText')}
      </Text>
      <Text
        content={t('sections.aboutUs.contactPerson.videoConferenceTitle')}
        mb={20}
        uppercase
        fontWeight={700}
      />
      <Text content={t('sections.aboutUs.contactPerson.videoConferenceText')} mb={15} />
    </Wrapper>
  )
}

CommonContactsText.propTypes = {}
CommonContactsText.defaultProps = {}

export default CommonContactsText
