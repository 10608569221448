import styled from 'styled-components'
import icon1 from 'images/sections/performance/icon1.inline.svg'
import icon2 from 'images/sections/performance/icon2.inline.svg'
import icon3 from 'images/sections/performance/icon3.inline.svg'

export const WrapperAll = styled.div(({theme}) => ({
  padding: '100px 0',
}))

export const WrapperTitle = styled.div(({theme}) => ({}))

export const ItemsOuter = styled.div(({theme}) => ({
  position: 'relative',
}))

export const AbsLine1 = styled.div(({theme}) => ({
  width: 136,
  height: 1,
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  background: theme.palette.primary.green,

  [theme.breakpoints.down('laptopS')]: {
    display: 'none',
  },
}))

export const AbsLine2 = styled.div(({theme}) => ({
  width: 136,
  height: 1,
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  background: theme.palette.primary.green,

  [theme.breakpoints.down('laptopS')]: {
    display: 'none',
  },
}))

export const ItemsInner = styled.div(({theme}) => ({
  margin: '0 -15px',
  display: 'flex',
  flexWrap: 'wrap',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))

export const ItemOuter = styled.div(({theme}) => ({
  padding: 15,
  flexBasis: '33.333%',

  [theme.breakpoints.down('tablet')]: {
    flexBasis: '100%',
  },
}))

export const ItemInner = styled.div(({theme}) => ({
  position: 'relative',
  padding: '50px 40px 15px 40px',
  minHeight: 200,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  boxShadow: `5px 16px 50px -24px rgba(20, 41, 61, 0.15)`,

  [theme.breakpoints.down('tablet')]: {
    padding: '15px',
  },
}))

export const Icon1 = styled(icon1)(({theme}) => ({
  position: 'absolute',
  top: 15,
  right: 10,
  width: 90,
  height: 90,
  flex: '0 0 90px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))

export const Icon2 = styled(icon2)(({theme}) => ({
  position: 'absolute',
  top: 15,
  right: 10,
  width: 90,
  height: 90,
  flex: '0 0 90px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))

export const Icon3 = styled(icon3)(({theme}) => ({
  position: 'absolute',
  top: 15,
  right: 10,
  width: 90,
  height: 90,
  flex: '0 0 90px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))
