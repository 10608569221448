import React from 'react'
// import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import img from 'images/sections/about-us/lawyer-photo.png'
import {
  InnerTextWrapper,
  InnerPhotoWrapper,
  Title,
  Name,
  LawyerText,
  InnerPhoto,
  InnerContent,
  Inner,
  ContactLink,
} from './ContactPerson.styles'
import Text from '../Text'
import CommonContactsText from '../CommonContactsText'
import VideoConferenceIcons from '../VideoConferenceIcons'

const ContactPerson = ({...rest}) => {
  const {t} = useI18next()
  return (
    <Inner>
      <InnerPhotoWrapper>
        <InnerPhoto src={img} />
        <InnerTextWrapper>
          <Title content={t('sections.aboutUs.contactPerson.title')} color="common.white" />
          <Name content={t('sections.aboutUs.contactPerson.lawyerName')} color="common.white" />
          <LawyerText
            content={t('sections.aboutUs.contactPerson.lawyerText')}
            color="common.white"
          />
        </InnerTextWrapper>
      </InnerPhotoWrapper>
      <InnerContent>
        <Text
          content={t('sections.aboutUs.contactPerson.bureau')}
          mb={10}
          uppercase
          fontWeight={700}
        />
        <Text content={t('sections.aboutUs.contactPerson.companyName')} mb={5} />
        <Text content={t('sections.aboutUs.contactPerson.street')} mb={10} />
        <ContactLink href="mailto:info@korten-ag.de">
          {t('sections.aboutUs.contactPerson.email')}
        </ContactLink>
        <ContactLink href="tel:+490408221822">
          {t('sections.aboutUs.contactPerson.tel')}
        </ContactLink>
        <ContactLink href="tel:+490408221823">
          {t('sections.aboutUs.contactPerson.fax')}
        </ContactLink>
        <CommonContactsText />
        <VideoConferenceIcons />
      </InnerContent>
    </Inner>
  )
}

ContactPerson.propTypes = {}
ContactPerson.defaultProps = {}

export default ContactPerson
