import styled from 'styled-components'
import icon1 from 'images/sections/legal/icon1.inline.svg'
import icon2 from 'images/sections/legal/icon2.inline.svg'
import icon3 from 'images/sections/legal/icon3.inline.svg'
import icon4 from 'images/sections/legal/icon4.inline.svg'

export const Wrapper = styled.div(({theme}) => ({
  padding: '120px 0',
  background: theme.palette.primary.bg,

  [theme.breakpoints.down('tabletL')]: {
    padding: '60px 0',
  },
}))

export const ItemsOuter = styled.div(({theme}) => ({
  position: 'relative',
}))

export const AbsLine1 = styled.div(({theme}) => ({
  width: 136,
  height: 1,
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  background: '#2E2E2E',

  [theme.breakpoints.down('tabletL')]: {
    display: 'none',
  },
}))

export const AbsLine2 = styled.div(({theme}) => ({
  width: 136,
  height: 1,
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  background: '#2E2E2E',

  [theme.breakpoints.down('tabletL')]: {
    display: 'none',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 960,
  margin: '0 auto',
}))

export const ItemsInner = styled.div(({theme}) => ({
  maxWidth: 960,
  margin: '0 -15px',
  display: 'flex',
  flexWrap: 'wrap',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))

export const ItemOuter = styled.div(({theme}) => ({
  padding: 15,
  flexBasis: '50%',

  [theme.breakpoints.down('tablet')]: {
    flexBasis: '100%',
  },
}))

export const ItemInner = styled.div(({theme}) => ({
  padding: '30px 40px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: `5px 16px 50px -24px rgba(20, 41, 61, 0.15)`,

  [theme.breakpoints.down('tablet')]: {
    padding: '15px',
  },
}))

export const Icon1 = styled(icon1)(({theme}) => ({
  marginRight: 15,
  width: 80,
  height: 80,
  flex: '0 0 80px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))

export const Icon2 = styled(icon2)(({theme}) => ({
  marginRight: 15,
  width: 80,
  height: 80,
  flex: '0 0 80px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))

export const Icon3 = styled(icon3)(({theme}) => ({
  marginRight: 15,
  width: 80,
  height: 80,
  flex: '0 0 80px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))

export const Icon4 = styled(icon4)(({theme}) => ({
  marginRight: 15,
  width: 80,
  height: 80,
  flex: '0 0 80px',

  [theme.breakpoints.down('tablet')]: {
    width: 40,
    height: 40,
    flex: '0 0 40px',
  },
}))
