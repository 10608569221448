import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import {
  Wrapper,
  TextWrapper,
  Line,
  Inner,
  Left,
  Numbers,
  NumbersItem,
  NumbersItemRow,
  NumbersItems,
  NumbersNum,
  LogoKorten,
  AbsLine2,
  AbsLine1,
} from './AboutUs.styles'
import Text from '../../components/Text'
import ContentContainer from '../../components/ContentContainer'
import ContactPerson from '../../components/ContactPerson'

const AboutUs = ({children, ...rest}) => {
  const {t} = useI18next()
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/logo-korten.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest} id="aboutUs">
      {/* <AbsLine1 /> */}
      {/* <AbsLine2 /> */}
      <ContentContainer>
        <Inner>
          <Left>
            <Text
              component="h2"
              content={t('sections.aboutUs.title')}
              size="titleSm"
              uppercase
              mb={30}
            />
            <TextWrapper>
              <Line />
              <Text content={t('sections.aboutUs.text')} size="md" />
            </TextWrapper>
            <Numbers>
              <Text
                content={t('sections.aboutUs.numbers.title')}
                size="titleXxs"
                uppercase
                mb={40}
              />
              <NumbersItems>
                <NumbersItemRow>
                  <NumbersItem width={230} mr={90}>
                    <NumbersNum mr={30}>11</NumbersNum>
                    <Text
                      content={t('sections.aboutUs.numbers.itemText1')}
                      fontWeight={700}
                      mb={0}
                    />
                  </NumbersItem>
                  <NumbersItem width={310}>
                    <NumbersNum mr={95}>44</NumbersNum>
                    <Text
                      content={t('sections.aboutUs.numbers.itemText2')}
                      fontWeight={700}
                      mb={0}
                    />
                  </NumbersItem>
                </NumbersItemRow>
                <NumbersItemRow>
                  <NumbersItem width={260} mr={60}>
                    <NumbersNum mr={30}>12</NumbersNum>
                    <Text
                      content={t('sections.aboutUs.numbers.itemText3')}
                      fontWeight={700}
                      mb={0}
                    />
                  </NumbersItem>
                  <NumbersItem width={290}>
                    <NumbersNum mr={30}>2003</NumbersNum>
                    <Text
                      content={t('sections.aboutUs.numbers.itemText4')}
                      fontWeight={700}
                      mb={0}
                    />
                  </NumbersItem>
                </NumbersItemRow>
                <NumbersItemRow>
                  <NumbersItem width={290}>
                    <NumbersNum mr={60}>3</NumbersNum>
                    <Text
                      content={t('sections.aboutUs.numbers.itemText5')}
                      fontWeight={700}
                      mb={0}
                    />
                  </NumbersItem>
                </NumbersItemRow>
              </NumbersItems>
            </Numbers>
            <LogoKorten src={img} fluid={img.childImageSharp.fluid} />
          </Left>
          <ContactPerson />
        </Inner>
      </ContentContainer>
    </Wrapper>
  )
}

AboutUs.propTypes = {}
AboutUs.defaultProps = {}

export default AboutUs
