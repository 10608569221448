import styled from 'styled-components'
import Img from 'gatsby-image'

export const Wrapper = styled.div(({theme}) => ({
  position: 'relative',
  padding: '120px 0',
  background: theme.palette.primary.bg,
}))

export const AbsLine1 = styled.div(({theme}) => ({
  position: 'absolute',
  top: 422,
  right: 0,
  width: 135,
  height: 1,
  background: theme.palette.primary.green,

  [theme.breakpoints.down('laptopM')]: {
    display: 'none',
  },
}))

export const AbsLine2 = styled.div(({theme}) => ({
  position: 'absolute',
  bottom: 315,
  right: 0,
  width: 135,
  height: 1,
  background: theme.palette.primary.green,

  [theme.breakpoints.down('laptopM')]: {
    display: 'none',
  },
}))

export const Inner = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
  },
}))

export const Left = styled.div(({theme}) => ({
  maxWidth: 740,

  [theme.breakpoints.down('tabletL')]: {
    marginBottom: 50,
  },
}))

export const TextWrapper = styled.div(({theme}) => ({
  marginBottom: 79,
  maxWidth: 620,
  display: 'flex',
}))

export const Line = styled.div(({theme}) => ({
  marginTop: 5,
  marginRight: 10,
  width: 50,
  flex: '0 0 50px',
  height: 9,
  background: '#C4C4C4',
}))

export const Numbers = styled.div(({theme}) => ({
  marginLeft: 50,
  marginBottom: 100,

  [theme.breakpoints.down('tabletL')]: {
    marginLeft: 0,
  },
}))

export const NumbersItems = styled.div(({theme}) => ({}))

export const NumbersItemRow = styled.div(({theme}) => ({
  display: 'flex',

  [theme.breakpoints.down('tabletL')]: {
    flexDirection: 'column',
  },
}))

export const NumbersItem = styled.div(({theme, width, mr}) => ({
  marginRight: mr,
  width,
  flex: `0 0 ${width}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('tabletL')]: {
    width: '100%',
    flex: `0 0 100%`,
    justifyContent: 'flex-start',
  },
}))

export const NumbersNum = styled.div(({theme, mr}) => ({
  marginRight: mr,
  color: theme.palette.primary.green,
  fontWeight: 700,
  fontSize: '64px',

  [theme.breakpoints.down('tabletL')]: {
    marginRight: 30,
    width: 130,
    flex: `0 0 130px`,
  },
}))

export const LogoKorten = styled(Img)(({theme}) => ({
  maxWidth: 200,
}))
